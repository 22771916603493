import ProviderIco from '../../../../../../assets/components/NavBar/ProviderIco.svg?react';
import styles from './ProviderItem.module.css';

export const ProviderItem = ({ name, onClick }) => {
  return (
    <div className={styles.providerItem} onClick={onClick}>
      <ProviderIco />
      <span className={styles.providerGames}>{name}</span>
    </div>
  );
};
