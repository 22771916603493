import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import styles from './BonusPageItem.module.css';

export const BonusPageItem = ({ onClick }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.bonusPageItem} onClick={onClick}>
      <div className={styles.bonusTextContainer}>
        <p className={styles.bonusText}>2000 рублей к депозиту</p>
        <div className={styles.forwardArrow} onClick={() => navigate('/bonusesPage')}>
          <ArrowLeftSvg />
        </div>
      </div>
    </div>
  );
};
