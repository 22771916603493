import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../../api/useCrmCall';
import { NotificationsContext } from '../../../../../context/NotificationsContext';
import { UserContext } from '../../../../../context/UserContext';

export const Callback = () => {
  const { login } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogin = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');

      if (authorizationCode) {
        try {
          const data = await postCrmRequest('/auth/login', { code: authorizationCode });

          login({
            user: data?.user,
            token: data?.token,
          });
          navigate('/');
        } catch (error) {
          setNotifications({ type: 'error', message: error?.response?.data?.message });
        }
      }
    };

    handleLogin();
  }, []);

  return <div>Авторизация...</div>;
};
