import categories from '../icons/categories.svg?react';
import news from '../icons/news.svg?react';
import pages from '../icons/pages.svg?react';
import providers from '../icons/providers.svg?react';

export const getIconByType = type => {
  switch (type) {
    case 'category':
      return categories;
    case 'news':
      return news;
    case 'page':
      return pages;
    case 'provider':
      return providers;
    default:
      return pages;
  }
};
