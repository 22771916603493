import ArrowLeftSvg from '../../../assets/components/Staff/ArrowLeftSvg.svg?react';
import styles from './Providers.module.css';

export const Providers = ({ changeContent }) => {
  const handleClick = newContent => {
    changeContent(newContent);
  };

  return (
    <>
      <div className={styles.backWrapper} onClick={() => handleClick('Profile')}>
        <ArrowLeftSvg />
        Providers
      </div>
      <div className={styles.content} />
    </>
  );
};
