import { useInfiniteQuery } from '@tanstack/react-query';

import { getNews } from '../api/get/getNews';

export const useInfiniteNews = () => {
  return useInfiniteQuery({
    queryKey: ['news'],
    queryFn: getNews,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return pages.length + 1; // Возвращаем следующую страницу только если есть данные
    },
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });
};
