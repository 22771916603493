import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { NotificationsContext } from '../../../../../../context/NotificationsContext';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentNovelties.module.scss';

export const ModalContentNovelties = () => {
  const [activeGameId, setActiveGameId] = useState(null);
  const { filteredGames, setFilteredGames } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  useDisableInteractionOnScroll();

  const getGamesByNovelties = useMutation({
    mutationFn: () => postCrmRequest(`/games/games_by_key`, { lang: 'ru', key: 'novelties' }),
    onSuccess: data => setFilteredGames(data),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  useEffect(() => {
    getGamesByNovelties.mutate();
  }, []);

  return (
    <div data-block-interactions={true} className={styles.gamesContainer}>
      {filteredGames?.map(game => (
        <GameItem
          key={game?.id}
          id={game?.id}
          game={game}
          activeGameId={activeGameId}
          setActiveGameId={setActiveGameId}
        />
      ))}
    </div>
  );
};
