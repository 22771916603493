import axios from 'axios';

export const verifyEmail = async data => {
  try {
    const response = await axios.put(`${window.location.protocol}//api.${window.location.hostname}/auth/change-userinfo`, data);

    if (response.status === 200) {
      const result = await axios.put(`${window.location.protocol}//api.${window.location.hostname}/auth/verify-email`, data);

      return result?.status;
    }
  } catch (error) {
    throw error?.response?.data?.message;
  }
};
