import { useContext, useEffect } from 'react';

import ArrowLeftSvg from '../../../../assets/components/Staff/ArrowLeftSvg.svg?react';
import { BonusesModalContext } from '../../../../context/BonusesModalContext';
import styles from './Bonuses.module.css';
import { ActiveBonusItem } from './ui/ActiveBonusItem/ActiveBonusItem';
import { BonusItem } from './ui/BonusItem/BonusItem';

export const Bonuses = ({ changeModalContent }) => {
  const {
    setEnabled,
    filteredBonuses: { inProgressBonus, allowedBonuses, notAllowedBonuses },
  } = useContext(BonusesModalContext);
  const handleClick = newContent => {
    changeModalContent(newContent);
  };

  useEffect(() => {
    setEnabled(true);
  }, []);

  return (
    <>
      {inProgressBonus ? (
        <ActiveBonusItem data={inProgressBonus} changeModalContent={changeModalContent} />
      ) : (
        <div className={styles.noBonuses}>
          <p className={styles.noBonusesText}>Нет активных бонусов</p>
        </div>
      )}

      <div className={styles.backWrapper} onClick={() => handleClick('Profile')}>
        <ArrowLeftSvg />
      </div>
      <div className={styles.content}>
        <>
          <p className={styles.blockTitle}>
            Доступные бонусы <span className={styles.bonusAmount}>{allowedBonuses?.length}</span>
          </p>
          <div className={styles.itemsContainer}>
            {allowedBonuses?.length ? (
              allowedBonuses?.map(item => (
                <BonusItem key={item?.id} data={item} changeModalContent={changeModalContent} />
              ))
            ) : (
              <div className={styles.noAllowedBonuses}>Нет доступных бонусов</div>
            )}
          </div>
        </>
        <>
          <p className={styles.blockTitle}>
            Остальные бонусы <span className={styles.bonusAmount}>{notAllowedBonuses?.length}</span>
          </p>
          <div className={styles.itemsContainer}>
            {notAllowedBonuses?.length ? (
              notAllowedBonuses?.map(item => (
                <BonusItem key={item?.id} data={item} changeModalContent={changeModalContent} />
              ))
            ) : (
              <div className={styles.noAllowedBonuses}>Нет других бонусов</div>
            )}
          </div>
        </>
      </div>
    </>
  );
};
