import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { UserContext } from '../../../context/UserContext';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { Button } from '../../atoms/Button/Button';
import { SendSmsButton } from '../../atoms/SendSmsButton/SendSmsButton';
import { ExpandableInput } from '../../molecules/ExpandableInput/ExpandableInput';
import { Input } from '../../molecules/Input/Input';
import { InputWithButton } from '../../molecules/InputWithButton/InputWithButton';
import { PasswordInput } from '../../molecules/PasswordInput/PasswordInput';
import styles from './Auth.module.css';
import { validateFormData } from './utils/validateFormData';

export const Register = () => {
  const [formData, setFormData] = useState({
    phone: '',
    password: '',
    repeatPassword: '',
    promoCode: '',
  });
  const [userData, setUserData] = useState();
  const { setNotifications } = useContext(NotificationsContext);
  const { login } = useContext(UserContext);
  const [fieldError, setFieldError] = useState({});
  const [regDisabled, setRegDisabled] = useState(false);
  const [codeDisabled, setCodeDisabled] = useState(true);
  const [codeExpires, setCodeExpires] = useState(null);
  const { postCrmRequest } = useCrmCall();

  const navigate = useNavigate();

  const handleChange = useCallback(e => {
    const { name, value } = e.target;

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      const errors = validateFormData(formData);
      if (Object.keys(errors).length > 0) {
        setFieldError(errors);
        return;
      }

      setFieldError({});

      try {
        if (!codeExpires) {
          const data = await postCrmRequest('/auth/register', {
            ...formData,
            phone: formData.phone.startsWith('7') ? formData.phone : `7${formData.phone}`,
          });
          setUserData(data);
          if (data.status === 'OK') {
            setRegDisabled(true);
            setCodeDisabled(false);
          }
        } else {
          const response = await postCrmRequest('/auth/verify-phone', {
            phoneNumber: `7${formData.phone}`,
            code: formData.code,
            username: userData?.user?.username,
            password: formData?.password,
          });
          if (response === 'OK') {
            const authData = await postCrmRequest('/auth/login', {
              ...formData,
              phone: formData.phone.startsWith('7') ? formData.phone : `7${formData.phone}`,
            });

            if (authData.status === 200) {
              login({
                isAuthenticated: authData?.data?.isAuthenticated,
                user: authData?.data?.user,
                token: authData?.data?.token,
              });
              navigate('/');
              setNotifications({ type: 'success', message: authData?.message });
            }
          }
        }
      } catch (error) {
        setNotifications({ type: 'error', message: error?.response?.data?.message });
      }
    },
    [formData, setNotifications, codeExpires],
  );

  const handleSendCode = useCallback(async () => {
    setCodeExpires(null);
    try {
      const data = await postCrmRequest('/auth/send-sms', {
        phoneNumber: `+7${formData?.phone}`,
      });
      setCodeExpires(data?.expires_in);
    } catch (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [formData.phone, setNotifications]);

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <Input
        labelText='Номер телефона'
        placeholder='Ввести номер'
        name='phone'
        type='tel'
        disabled={regDisabled}
        value={formData.phone}
        onChange={handleChange}
        masked={true}
      />
      {fieldError.phone && <p className={styles.errorField}>{fieldError.phone}</p>}
      <PasswordInput
        labelText='Пароль'
        placeholder='Ввести пароль'
        name='password'
        disabled={regDisabled}
        value={formData.password}
        onChange={handleChange}
      />
      {fieldError.password && <p className={styles.errorField}>{fieldError.password}</p>}
      <PasswordInput
        name='repeatPassword'
        labelText='Повторите пароль'
        placeholder='Повторить пароль'
        disabled={regDisabled}
        value={formData.repeatPassword}
        onChange={handleChange}
      />
      {fieldError.password && <p className={styles.errorField}>{fieldError.password}</p>}
      <InputWithButton
        labelText='Код из СМС'
        placeholder='Ввести код'
        name='code'
        disabled={codeDisabled}
        value={formData.code}
        onChange={handleChange}
      >
        <SendSmsButton buttonDisabled={codeDisabled} onButtonClick={handleSendCode} buttonText='Отправить код' />
      </InputWithButton>
      <ExpandableInput
        name='promoCode'
        title='Промо-код (необязательно)'
        value={formData.promoCode}
        disabled={regDisabled}
        onChange={handleChange}
      />
      <Button buttonText={regDisabled ? 'Подтвердить номер' : 'Зарегистрироваться'} />
    </form>
  );
};
