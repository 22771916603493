import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { convertDateFormat } from '../../../utils/convertDateFormat';
import styles from './TransactionItem.module.css';
import { getIconByStatus } from './utils/getIconByStatus';

export const TransactionItem = ({ item }) => {
  const { t } = useTranslation('Transactions');

  const Icon = getIconByStatus(item?.status);

  return (
    <div className={styles.container}>
      <Icon className={styles.icon} />
      <div className={styles.infoBlock}>
        <p className={styles.type}>{t(item?.type)}</p>
        <p className={styles.date}>{convertDateFormat(item?.date, 'dd/MM/yyyy HH:mm:ss')}</p>
      </div>
      {item?.type === 'payin' && <span className={clsx(styles.amount, styles.blue)}>+{item?.credit}₽</span>}
      {item?.type === 'payout' && <span className={clsx(styles.amount, styles.red)}>-{item?.credit}₽</span>}
    </div>
  );
};
