import { useTranslation } from 'react-i18next';

import styles from './ContentSwitcher.module.css';

export const ContentSwitcher = ({ handleClick, active = 'Deposit', buttonsMap }) => {
  const { t } = useTranslation('ContentSwitcher');
  const defaultButtons = [
    {
      name: t('deposit'),
      content: 'Deposit',
    },
    {
      name: t('withdrawal'),
      content: 'Withdrawal',
    },
    {
      name: t('bank'),
      content: 'Bank',
    },
  ];

  const buttons = buttonsMap || defaultButtons;

  return (
    <div className={styles.contentSwitcher}>
      {buttons.map(item => (
        <button
          key={item.content}
          className={item.content === active ? styles.active : styles.inActive}
          onClick={() => handleClick(item.content.toLowerCase())}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
};
