import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { IMaskInput } from 'react-imask';

import { useCrmCall } from '../../../../api/useCrmCall';
import { ModalContext } from '../../../../context/ModalContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { getModalIconByMethod } from '../../../../utils/getModalIconByMethod';
import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../molecules/Input/Input';
import styles from './WithdrawalRequisitesAdd.module.css';

export const WithdrawalRequisitesAdd = ({ changeModalContent }) => {
  const { modalState, setModalState, setPageFrom } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  const checkRequisiteMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/check_add_requisite', data),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });
  const smsMutation = useMutation({
    mutationFn: state => postCrmRequest('/payment/send_verification_sms', state),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  useEffect(() => {
    setModalState(prevState => ({ ...prevState, requisiteNumber: '' }));
  }, []);

  const Icon = getModalIconByMethod(modalState?.paymentMethod);

  const handleChange = e => {
    const regex = /^\d*$/;

    if (modalState?.paymentMethod === 'SBP P2P RUB' || regex.test(e.target?.value)) {
      setModalState(prevState => ({ ...prevState, [e.target?.name || 'requisiteNumber']: e.target?.value }));
    }
  };

  const data = {
    reqType: modalState?.paymentMethod,
    requisiteNumber: modalState?.requisiteNumber,
    comment: modalState?.bankName,
  };

  const smsData = {
    actionType: 'add_requisite',
  };

  const handleSubmit = () => {
    if (modalState?.paymentMethod === 'MC/VISA/MIR P2P RUB' && modalState?.requisiteNumber.length < 16) {
      setNotifications({ type: 'error', message: 'ERROR_REQUISITE_LENGTH' });
    }

    checkRequisiteMutation.mutate(data, {
      onSuccess: () => {
        smsMutation.mutate(smsData);
        setPageFrom('withdrawal');
        changeModalContent('withdrawal-step4', 'Введите код из СМС', true);
      },
    });
  };
  // TODO: подумать как сделать универсально
  return (
    <>
      {modalState?.paymentMethod === 'SBP P2P RUB' ? (
        <>
          <label className={styles.cardLabel} htmlFor='requisiteNumber'>
            Номер телефона
          </label>
          <div className={styles.cardField}>
            <div className={styles.icon}>
              <Icon />
            </div>
            <input
              id='requisiteNumber'
              name='requisiteNumber'
              value={modalState?.requisiteNumber}
              type='text'
              placeholder='Введите номер телефона'
              onChange={handleChange}
              maxLength={11}
            />
          </div>
          <Input
            labelText='Название банка'
            id='bankName'
            name='bankName'
            value={modalState?.bankName}
            type='text'
            placeholder='Введите название банка'
            onChange={handleChange}
          />
        </>
      ) : (
        <>
          <label className={styles.cardLabel} htmlFor='requisiteNumber'>
            Номер карты
          </label>
          <div className={styles.cardField}>
            <div className={styles.icon}>
              <Icon />
            </div>
            <IMaskInput
              id='requisiteNumber'
              name='requisiteNumber'
              mask='0000 0000 0000 0000'
              value={modalState?.requisiteNumber}
              unmask={true}
              onAccept={value => handleChange({ target: { value } })}
              placeholder='Введите номер карты'
              maxLength={19}
            />
          </div>
        </>
      )}
      <Button
        buttonText={'Продолжить'}
        isLoading={checkRequisiteMutation.isPending || smsMutation.isPending}
        onClick={handleSubmit}
      />
    </>
  );
};
