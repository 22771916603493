import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import { convertDateFormat } from '../../../utils/convertDateFormat';
import { prepareNewsImageString } from '../../../utils/prepareNewsImageString';
import { Header } from '../../organisms/Header/Header';
import styles from './NewsDetailPage.module.css';

export const NewsDetailPage = () => {
  const [newsItem, setNewsItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state: { news },
  } = useLocation();

  useEffect(() => {
    const newsById = news?.flat()?.find(item => item.id === Number(id));
    setNewsItem(newsById);
  }, [news, id]);

  const openModal = () => {
    setModalIsOpen(true);
    navigate('/profile');
  };

  const img = prepareNewsImageString(
    newsItem?.local_ru?.short_text,
    import.meta.env.VITE_STATIC_DOMAIN,
    newsItem?.local_ru?.alt_desc,
  );

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.newsDetailPage}>
      <Header openModal={openModal} />
      <div className={styles.newsHeader}>
        <div className={styles.backArrow} onClick={() => navigate('/')}>
          <ArrowLeftSvg />
        </div>
        <div>
          <h1 className={styles.newsTitle}>{newsItem?.local_ru?.title}</h1>
          <p className={styles.newsDate}>{convertDateFormat(newsItem?.publishedAt)}</p>
        </div>
      </div>
      <div
        className={styles.newsImage}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(img),
        }}
      />
      <div
        className={styles.newsFullText}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(newsItem?.local_ru?.full_text),
        }}
      />
    </div>
  );
};
