/**
 * Подменяет `__domain__images` в строке на значение из переменной окружения,
 * удаляет теги <p> и добавляет атрибут alt к тегу <img>.
 *
 * @param {string} inputString - Строка, содержащая подменяемое значение.
 * @param {string} envVariable - Значение переменной окружения.
 * @param {string} altText - Значение для атрибута alt тега <img>.
 * @returns {string} Строка с подмененным значением, удаленными тегами <p> и добавленным атрибутом alt.
 */
export const prepareNewsImageString = (inputString, envVariable, altText = '') => {
  // Заменяем __domain__ на значение переменной окружения
  let resultString = inputString?.replace(/__domain__/g, envVariable);

  // Удаляем все теги <p> и </p>
  resultString = resultString?.replace(/<\/?p>/g, '');

  // Удаляем атрибут style из тега <img>
  resultString = resultString?.replace(/<img[^>]*style="[^"]*"[^>]*>/g, match => match.replace(/style="[^"]*"/g, ''));

  // Добавляем атрибут alt к тегу <img>
  resultString = resultString?.replace(/<img(.*?)alt=".*?"(.*?)\/>/, `<img$1alt="${altText}"$2/>`);

  // Если тег <img> не имеет атрибута alt, добавляем его
  resultString = resultString?.replace(/<img(?!.*?alt=)(.*?)\/>/, `<img$1 alt="${altText}" />`);

  return resultString;
};
