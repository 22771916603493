import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { memo, useContext, useState } from 'react';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowSvg from '../../../assets/components/Profile/ArrowSvg.svg?react';
import { ModalContext } from '../../../context/ModalContext';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { UserContext } from '../../../context/UserContext';
import { useTranslateModalTitle } from '../../../hooks/useTranslateModalTitle';
import { getModalIconByMethod } from '../../../utils/getModalIconByMethod';
import { Button } from '../../atoms/Button/Button';
import styles from './CardSelect.module.css';
import DeleteSvg from './delete.svg?react';

const CardSelectComponent = ({ activeCard, setActiveCard, changeModalContent }) => {
  const { userState } = useContext(UserContext);
  const { modalState } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();

  const [isExpanded, setIsExpanded] = useState(false);
  const titles = useTranslateModalTitle('modalTitles', modalState?.paymentMethod);

  const deleteMutation = useMutation({
    mutationFn: id => postCrmRequest('/payment/payout_delete_requisite', { reqId: id }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const Icon = getModalIconByMethod(modalState?.paymentMethod);

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCardChoose = card => {
    setActiveCard(card);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    deleteMutation.mutate(id);
  };
  const client = userState?.crmUserData;

  const requisitesByMethod = client?.cards?.filter(item => item.type === modalState?.paymentMethod);

  return (
    <div className={styles.cardSelect} onClick={toggleDropdown}>
      <label className={styles.label} htmlFor='cardInfo'>
        Номер карты
      </label>
      <div id='cardInfo' className={clsx(styles.cardDropdown, styles.main)}>
        {activeCard ? (
          <>
            <div className={styles.icon}>
              <Icon />
            </div>
            <div className={styles.cardInfo}>
              <div className={styles.cardTitle}>{`Карта ${activeCard?.index}`}</div>
              <div className={styles.cardNumber}>{activeCard?.cardNumber}</div>
            </div>
          </>
        ) : (
          <div className={styles.cardInfo}>
            <div className={styles.cardNumberMain}>Выберите карту</div>
          </div>
        )}
        <div className={styles.cardArrow}>
          <ArrowSvg />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.cardOptions}>
          {requisitesByMethod?.length > 0 ? (
            requisitesByMethod.map((card, index) => (
              <div
                key={card?.id}
                onClick={() => handleCardChoose({ index: index + 1, ...card })}
                className={styles.cardDropdown}
              >
                <div className={styles.icon}>
                  <Icon />
                </div>
                <div className={styles.cardInfo}>
                  <div className={styles.cardTitle}>{`Карта ${index + 1}`}</div>
                  <div className={styles.cardNumber}>{card?.cardNumber}</div>
                </div>
                <div className={styles.deleteIcon} onClick={e => handleDelete(e, card?.id)}>
                  <DeleteSvg />
                </div>
              </div>
            ))
          ) : (
            <p>Карт не найдено</p>
          )}
          <Button
            buttonText={modalState?.paymentMethod === 'SBP P2P RUB' ? 'Добавить СБП' : 'Добавить карту'}
            onClick={() => changeModalContent('withdrawal-step3', titles.add, true)}
          />
        </div>
      )}
    </div>
  );
};

export const CardSelect = memo(CardSelectComponent);
