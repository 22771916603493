import { ModalContentCategory } from './ui/ModalContentCategory/ModalContentCategory';
import { ModalContentFavorite } from './ui/ModalContentFavorite/ModalContentFavorite';
import { ModalContentFilteredGames } from './ui/ModalContentFilteredGames/ModalContentFilteredGames';
import { ModalContentNovelties } from './ui/ModalContentNovelties/ModalContentNovelties';
import { ModalContentProviders } from './ui/ModalContentProviders/ModalContentProviders';

export const modalGamesRoutes = {
  favorite: ModalContentFavorite,
  category: ModalContentCategory,
  providers: ModalContentProviders,
  novelties: ModalContentNovelties,
  'category/games': ModalContentFilteredGames,
  'providers/games': ModalContentFilteredGames,
};
