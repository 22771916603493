import CardSvg from '../components/molecules/CardSelect/icons/card.svg?react';
import SbpSvg from '../components/molecules/CardSelect/icons/sbp.svg?react';

export const getModalIconByMethod = method => {
  const modalTitleMap = {
    'MC/VISA UAH': CardSvg,
    'SBP P2P RUB': SbpSvg,
    'MC/VISA/MIR P2P RUB': CardSvg,
  };

  return modalTitleMap[method] || modalTitleMap['MC/VISA UAH'];
};
