import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import Avatar from '../../../../assets/components/Header/Avatar.svg?react';
import ArrowRightSvg from '../../../../assets/components/Profile/ArrowRightSvg.svg?react';
import BonusSvg from '../../../../assets/components/Profile/BonusSvg.svg?react';
import CopySvg from '../../../../assets/components/Profile/CopySvg.svg?react';
import EditSvg from '../../../../assets/components/Profile/EditSvg.svg?react';
import LogOutSvg from '../../../../assets/components/Profile/LogOutSvg.svg?react';
import LoyaltySvg from '../../../../assets/components/Profile/LoyaltySvg.svg?react';
import NotVerifSvg from '../../../../assets/components/Profile/NotVerifSvg.svg?react';
import ProvidersSvg from '../../../../assets/components/Profile/ProvidersSvg.svg?react';
import SettingSvg from '../../../../assets/components/Profile/SettingSvg.svg?react';
import TransactionsSvg from '../../../../assets/components/Profile/TransactionsSvg.svg?react';
import VerifSvg from '../../../../assets/components/Profile/VerifSvg.svg?react';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { UserContext } from '../../../../context/UserContext';
import styles from './Profile.module.css';
import { WalletDropdown } from './ui/WalletDropdown';

export const Profile = ({ changeModalContent }) => {
  const { logout, userState } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { getCrmRequest } = useCrmCall();

  const { t } = useTranslation('Profile');

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await getCrmRequest(`/auth/logout`);

      if (response.status === 200) {
        logout();
        navigate('/');
      }
    } catch (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  };

  const handleCopy = copyText => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setNotifications({ type: 'notification', message: 'SUCCESS_COPY_ID' });
      })
      .catch(() => {
        setNotifications({ type: 'error', message: 'ERROR_COPY_ID' });
      });
  };
  const client = userState?.crmUserData;

  return (
    <div className={styles.profileModalContent}>
      <div className={styles.profileLogo}>
        <div className={styles.profileIco}>
          <Avatar />
          {/*<img src={user?.data?.image_url} alt='NoData' className={styles.profileImage} />*/}
        </div>
        <div className={styles.profileVerif}>{!client?.is_verified ? <VerifSvg /> : <NotVerifSvg />}</div>
      </div>

      <div className={styles.userInfo}>
        <div className={styles.userInfoUsername}>
          {client?.username}
          {/*<EditSvg />*/}
        </div>
        <div className={styles.userInfoID}>
          {t('id')}: {client?.id}
          <button className={styles.copyButton} onClick={() => handleCopy(client?.id)}>
            <CopySvg />
          </button>
        </div>
      </div>

      <WalletDropdown user={client} />
      <div className={styles.buttonsNav}>
        <div className={styles.buttons} onClick={() => changeModalContent('deposit')}>
          {t('deposit')}
        </div>
        <div className={styles.buttons} onClick={() => changeModalContent('withdrawal')}>
          {t('withdrawal')}
        </div>
        <div className={styles.buttons} onClick={() => changeModalContent('bank')}>
          {t('bank')}
        </div>
      </div>

      <div className={styles.navWrapper}>
        <div className={styles.navComponent}>
          <div className={styles.navComponentIco}>
            <BonusSvg />
          </div>
          {t('bonuses')}
          <div className={styles.redirectButton} onClick={() => changeModalContent('profile/bonuses', 'Лояльность')}>
            <ArrowRightSvg />
          </div>
        </div>
        <div className={styles.navComponent}>
          <div className={styles.navComponentIco}>
            <LoyaltySvg />
          </div>
          {t('loyalty')}
          <div className={styles.redirectButton} onClick={() => changeModalContent('profile/loyalty')}>
            <ArrowRightSvg />
          </div>
        </div>
        <div className={styles.navComponent}>
          <div className={styles.navComponentIco}>
            <ProvidersSvg />
          </div>
          {t('providers')}
          <div className={styles.redirectButton} onClick={() => changeModalContent('profile/providers')}>
            <ArrowRightSvg />
          </div>
        </div>
        <div className={styles.navComponent}>
          <div className={styles.navComponentIco}>
            <TransactionsSvg />
          </div>
          {t('transactions')}
          <div className={styles.redirectButton} onClick={() => changeModalContent('profile/transactions')}>
            <ArrowRightSvg />
          </div>
        </div>
        <div className={styles.navComponent}>
          <div className={styles.navComponentIco}>
            <SettingSvg />
          </div>
          {t('settings')}
          <div className={styles.redirectButton} onClick={() => changeModalContent('profile/settings', 'Настройки')}>
            <ArrowRightSvg />
          </div>
        </div>
      </div>

      <button className={styles.logOut} onClick={handleLogout}>
        <div className={styles.logOutWrapper}>
          {t('logout')}
          <LogOutSvg />
        </div>
      </button>
    </div>
  );
};
