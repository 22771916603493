import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '../../../assets/components/SearchBar/search.svg?react';
import { GameItem } from '../../pages/_modals/GamesModal/ui/GameItem/GameItem';
import styles from './SearchBar.module.scss';
import { getIconByType } from './utils/getIconByType';

export const SearchBar = ({ style }) => {
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [searchResults, setSearchResults] = useState();
  const [activeGameId, setActiveGameId] = useState(null);
  const navigate = useNavigate();

  const handleChange = e => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  useEffect(() => {
    if (debouncedValue) {
      const fetchData = async () => {
        const { data } = await axios.get(
          `${window.location.protocol}//${window.location.hostname}/search?query=${debouncedValue}&language=ru`,
        );
        setSearchResults(data?.hits?.hits);
      };

      fetchData();
    }
  }, [debouncedValue]);

  const handlePageClick = page => {
    navigate(`/${page}`);
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchBar} style={style}>
        <SearchIcon width='40' height='40' viewBox='0 0 40 40' />
        <input className={styles.searchInput} value={value} onChange={handleChange} type='text' placeholder='Поиск' />
      </div>

      {searchResults && (
        <div className={styles.searchResults}>
          <div className={styles.searchResultsPages}>
            {searchResults
              ?.filter(item => item?._source?.type !== 'game')
              .slice(0, 5)
              .map(item => {
                const Icon = getIconByType(item?._source?.type);
                return (
                  <div
                    key={item?._source?.title_ru}
                    className={styles.pageResult}
                    onClick={() => handlePageClick(item?._source?.url_key)}
                  >
                    <Icon /> <p className={styles.pageResultText}>{item?._source?.title_ru}</p>
                  </div>
                );
              })}
          </div>
          <div className={styles.searchResultsGames}>
            {searchResults?.map(
              item =>
                item?._source?.type === 'game' && (
                  <GameItem
                    key={item?._id}
                    id={item?._id}
                    game={item?._source}
                    activeGameId={activeGameId}
                    setActiveGameId={setActiveGameId}
                  />
                ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};
