import { useContext, useEffect } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { GamesModalContext } from '../../../context/GamesModalContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { NavBarHome } from '../../organisms/NavBar/NavBarHome/NavBarHome';
import { News } from '../../organisms/News/News';
import { Recommended } from '../../organisms/Recommended/Recommended';
import { SearchBar } from '../../organisms/SearchBar/SearchBar';
import { modalGamesRoutes } from '../_modals/GamesModal/ModalContent';
import styles from './Home.module.scss';

export const Home = () => {
  const { modalContent, setModalContent, modalIsOpen, setModalIsOpen, closeModal } = useContext(GamesModalContext);
  const location = useLocation();
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (modalGamesRoutes[path]) {
      setModalIsOpen(true);
      setModalContent(path);
    } else {
      setModalIsOpen(false);
    }
  }, [location]);

  const ModalGamesComponent = modalGamesRoutes[modalContent];
  return (
    <>
      {isDesktop && (
        <div className={styles.desktopSearchWrapper}>
          <NavBarHome />
          <SearchBar />
        </div>
      )}
      {(isMobile || isTablet) && (
        <>
          <SearchBar />
          <NavBarHome />
        </>
      )}
      <Recommended />
      {isMobile && <span className={styles.newsHeader}>Новости</span>}
      <News />

      {ModalGamesComponent && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={true}
          className={styles.navBarModal}
          overlayClassName={styles.navBarModalOverlay}
        >
          <div className={styles.navBarModalLine} />
          <div className={styles.navBarModaWrapper}>
            <NavBarHome style={{ marginBottom: '0px' }} />
            <div className={styles.modalContentWrapper}>
              <div data-block-interactions={true} className={styles.modalContent}>
                <ModalGamesComponent />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
