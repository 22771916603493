import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './NotFoundPage.module.css';

export const NotFoundPage = () => {
  return (
    <>
      <div className={styles.container}>
        <p className={styles.notFoundCode}>404</p>
        <p className={styles.notFoundText}>Что-то пошло не так! Страница не существует либо временно недоступна.</p>
      </div>
      <NavBarBottom />
    </>
  );
};
