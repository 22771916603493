import AvelibleSvg from '../../../assets/components/Loyalty/AvelibleSvg.svg?react';
import LockSvg from '../../../assets/components/Loyalty/LockSvg.svg?react';
import styles from './PrivilegeItem.module.scss';

export const PrivilegeItem = ({ text, disable = false }) => {
  return (
    <div className={styles.loyalty}>
      <p className={disable ? styles.disable : styles.active}>{text}</p>
      <div className={styles.icon}>{disable ? <LockSvg /> : <AvelibleSvg />}</div>
    </div>
  );
};
