import { getPaymentIcon } from '../../../utils/getPaymentIcon';
import styles from './PaymentCard.module.css';

export const PaymentCard = ({ methodName, onClick, className }) => {
  return (
    <div key={methodName} className={`${styles.methodCard} ${className}`} onClick={() => onClick(methodName)}>
      <span className={styles.methodsIcon}>{getPaymentIcon(methodName)}</span>
      {methodName}
    </div>
  );
};
