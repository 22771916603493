import { createContext, useState } from 'react';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const handleSetNotifications = obj => {
    if (obj?.message) {
      setNotifications(prev =>
        prev.some(item => item.message === obj?.message)
          ? prev.map(item => (item.message === obj?.message ? { ...item, repeat: true } : item))
          : [...prev, { ...obj, repeat: false }],
      );
    }

    // // Перезапустить анимацию
    // setTimeout(() => {
    //   setNotifications(prev =>
    //     prev.map(item => (item.message === obj?.message && item.repeat === true ? { ...item, repeat: false } : item)),
    //   );
    // }, 500);
  };

  const deleteNotification = message => {
    setNotifications(prev => [...prev.filter(item => item?.message !== message)]);
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, setNotifications: handleSetNotifications, deleteNotification }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
