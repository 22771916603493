import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useModal } from '../../../hooks/useModal';
import { BackButton } from '../../atoms/BackButton/BackButton';
import { LogoComponent } from '../../atoms/Logo/Logo';
import { ModalComponent } from '../_modals/ModalComponent/ModalComponent';
import { PasswordRecovery } from '../_modals/PasswordRecovery/PasswordRecovery';
import { Login } from '../Auth/Login';
import { Register } from '../Auth/Register';
import styles from './Sign.module.css';

export const Sign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isRegister = location.pathname === '/register';

  const {
    modalIsOpen,
    modalContent,
    setModalContent,
    modalName,
    closeModal,
    handleGoBack,
    changeModalContent,
    setModalIsOpen,
  } = useModal();

  const toggleState = () => {
    navigate(isRegister ? '/login' : '/register'); // Меняем путь на противоположный
  };

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (modalRoutes[path]) {
      setModalIsOpen(true);
      setModalContent(path);
    } else {
      setModalIsOpen(false);
    }
  }, [location]);

  const modalRoutes = {
    'login/recovery': PasswordRecovery,
  };
  const ModalContentComponent = modalRoutes[modalContent];

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <BackButton className={styles.backButton} navigateTo='/' />
        <div className={styles.logo}>
          <LogoComponent variant='big' />
        </div>
      </div>
      <div className={styles.toggleContainer}>
        <div className={`${styles.toggleSwitch} ${!isRegister ? styles.right : styles.left}`} />
        <button className={styles.toggleButton} onClick={toggleState}>
          <span className={`${styles.signupText} ${!isRegister ? styles.textOut : styles.textOn}`}>Регистрация</span>
          <span className={`${styles.signinText} ${!isRegister ? styles.textOn : styles.textOut}`}>Войти</span>
        </button>
      </div>
      {isRegister ? <Register /> : <Login changeModalContent={changeModalContent} />}

      <ModalComponent
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        modalContent={modalContent}
        modalName={modalName}
        handleGoBack={handleGoBack}
        closeModal={closeModal}
        Content={() => (
          <ModalContentComponent
            changeModalContent={changeModalContent}
            setModalContent={setModalContent}
            closeModal={closeModal}
          />
        )}
      />
    </div>
  );
};
