export const validateFormData = formData => {
  const errors = {};

  if (formData?.phone.length < 10) {
    errors.phone = 'Номер указан неправильно';
  }

  if (formData.password !== formData.repeatPassword) {
    errors.password = 'Пароли не совпадают';
  } else if (formData.password.length < 8) {
    errors.password = 'Пароль должен быть не короче 8 символов';
  }

  return errors;
};
