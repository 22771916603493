import { clsx } from 'clsx';
import { memo, useCallback, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

import styles from './SendSmsButton.module.css';

export const SendSmsButton = memo(({ buttonText, buttonDisabled, onButtonClick }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isTimerEnd, setIsTimerEnd] = useState(true);
  const [startTimer, setStartTimer] = useState(false);

  const handleClick = () => {
    if (!isClicked && isTimerEnd) {
      setIsClicked(true);
      setIsTimerEnd(false);
      setStartTimer(true);
      onButtonClick();
    }
  };

  const renderer = useCallback(
    ({ minutes, seconds, completed }) => {
      if (completed) {
        setIsClicked(false);
        return <>{buttonText}</>;
      }
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    },
    [buttonText],
  );

  const handleComplete = useCallback(() => {
    setIsTimerEnd(true);
    setStartTimer(false);
  }, []);

  return (
    <button
      className={clsx(styles.inputButton, buttonDisabled && styles.disabled)}
      type='button'
      onClick={handleClick}
      disabled={buttonDisabled || (isClicked && !isTimerEnd)}
    >
      {startTimer ? (
        <Countdown date={Date.now() + 120000} renderer={renderer} onComplete={handleComplete} />
      ) : (
        buttonText
      )}
    </button>
  );
});
