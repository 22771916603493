import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

import { useCrmCall } from '../../../api/useCrmCall';
import { ModalContext } from '../../../context/ModalContext';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { PaymentCard } from '../../atoms/PaymentCard/PaymentCard';
import styles from './PaymentMethods.module.css';

export const PaymentMethods = ({ header, onSelect = () => {} }) => {
  const { modalState, setModalState } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { getCrmRequest } = useCrmCall();
  const {
    data: paymentMethods,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: () => getCrmRequest('/payment/methods'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  const handleCardClick = methodName => {
    setModalState(prevState => ({ ...prevState, paymentMethod: methodName }));
    onSelect(methodName);
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className={styles.methodsContainer}>
      <p className={styles.methodsLabel}>{header}</p>
      <div className={styles.methods}>
        {paymentMethods?.map(({ methodName }) => {
          return (
            <PaymentCard
              key={methodName}
              className={modalState?.paymentMethod === methodName ? styles.selected : ''}
              methodName={methodName}
              onClick={handleCardClick}
            />
          );
        })}
      </div>
    </div>
  );
};
