import { useCallback, useState } from 'react';

import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../molecules/Input/Input';

export const ChangePhone = () => {
  const [inputState, setInputState] = useState('');

  const handleChange = useCallback(e => {
    const regExp = /^[0-9]*$/; // Разрешаем только цифры

    if (regExp.test(e.target.value)) {
      setInputState(e.target.value);
    }
  }, []);

  return (
    <>
      <Input
        name='changePhone'
        labelText='Новый номер телефона'
        maxLength={11}
        value={inputState}
        onChange={handleChange}
      />
      <Button buttonText='Продолжить' />
    </>
  );
};
