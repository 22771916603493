import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { authUrl, registerUrl } from '../../../../../../constants';
import { UserContext } from '../../../../../../context/UserContext';
import styles from './GameItem.module.css';

export const GameItem = ({ id, game, activeGameId, setActiveGameId }) => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();
  const imgSource = game?.main_image || game?.img;
  const img = imgSource?.replace(/__domain__/g, import.meta.env.VITE_STATIC_DOMAIN);
  const gameKey = game?.key || game?.url_key;

  const handleGameClick = () => {
    if (!isAuthenticated) window.location = registerUrl;
    if (activeGameId === id) {
      navigate(`/game/${gameKey}`, { state: { id } });
    } else {
      setActiveGameId(id);
    }
  };

  return (
    <div
      onClick={handleGameClick}
      className={styles.gameIco}
      style={{ transform: activeGameId === id ? 'scale(0.95)' : 'scale(1)' }}
    >
      <img src={img} alt={`VOCXOD_${game?.key}`} />
    </div>
  );
};
