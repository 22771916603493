import * as Toast from '@radix-ui/react-toast';
import { useContext } from 'react';

import { NotificationsContext } from '../../../context/NotificationsContext';
import { NotificationsToast } from '../../molecules/NotificationsToast/NotificationsToast';
import styles from './Notifications.module.css';

export const Notifications = () => {
  const { notifications } = useContext(NotificationsContext);

  return (
    <Toast.Provider>
      {notifications?.map(item => (
        <NotificationsToast key={item?.message} {...item} />
      ))}
      <Toast.Viewport className={styles.toastViewport} />
    </Toast.Provider>
  );
};
