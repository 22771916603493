const theme = localStorage.getItem('theme');
const alanbaseId = localStorage.getItem('ref_code') || 'TESTTESTTEST';

const clientId = 'Web App Client';
const scope = 'openid email';

const ssoBaseUrl = `${window.location.protocol}//sso.${window.location.hostname}/realms/vocxod/protocol/openid-connect`;
const redirectUri = `${window.location.protocol}//${window.location.hostname}/callback`;

function createUrl(path, query) {
  return `${ssoBaseUrl}/${path}?${new URLSearchParams(query).toString()}`;
}

export const authUrl = createUrl('auth', {
  client_id: clientId,
  kc_locale: 'ru',
  redirect_uri: redirectUri,
  response_type: 'code',
  scope: scope,
  theme: theme,
});

export const registerUrl = createUrl('registrations', {
  client_id: clientId,
  kc_locale: 'ru',
  redirect_uri: redirectUri,
  ref_code: alanbaseId,
  response_type: 'code',
  scope: scope,
  theme: theme,
});