import { useContext } from 'react';

import { ThemeContext } from '../../../../../context/ThemeContext';
import MoonSVG from '../assets/Moon.svg?react';
import SunSVG from '../assets/Sun.svg?react';
import styles from './ThemeSwitcher.module.css';

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div className={styles.themeSwitcher}>
      <button className={theme === 'light' ? styles.active : styles.inActive} onClick={() => setTheme('light')}>
        <SunSVG />
        Светлая
      </button>
      <button className={theme === 'dark' ? styles.active : styles.inActive} onClick={() => setTheme('dark')}>
        <MoonSVG />
        Темная
      </button>
    </div>
  );
};
