import DOMPurify from 'dompurify';
import { forwardRef, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { convertDateFormat } from '../../../../utils/convertDateFormat';
import { prepareNewsImageString } from '../../../../utils/prepareNewsImageString';
import styles from '../News.module.scss';

export const NewsItem = forwardRef(({ news, newsItem: { local_ru, id, publishedAt } }, ref) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const handleNewsClick = () => {
    navigate(`/news/${id}`, { state: { news } });
  };

  const img = prepareNewsImageString(local_ru?.short_text, import.meta.env.VITE_STATIC_DOMAIN, local_ru?.alt_desc);

  return (
    <div ref={ref} className={styles.newsItem} onClick={handleNewsClick}>
      <div
        className={styles.newsImage}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(img),
        }}
      />
      <div className={styles.newsPanel}>
        <div className={styles.panelInfo}>
          <div>
            <h5>{local_ru?.title}</h5>
            {!isMobile && (
              //TODO хардкод
              <p className={styles.smallText}>
                В нашем разделе «Новые» появились свежие слоты, готовые порадовать вас необычными механиками и бонусами.
              </p>
            )}
          </div>
          {/* //TODO непонятки с датами */}
          <span className={styles.date}>{convertDateFormat('2011-10-05T14:48:00.000Z')}</span>
          {/* <span className={styles.date}>{convertDateFormat(publishedAt)}</span> */}
        </div>
      </div>
    </div>
  );
});
