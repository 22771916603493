import { useCallback, useState } from 'react';

import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../molecules/Input/Input';

export const PasswordRecovery = ({ changeModalContent }) => {
  const [phone, setPhone] = useState();

  const handleChange = useCallback(event => {
    setPhone(event.target.value);
  }, []);

  const handleClick = useCallback(() => changeModalContent('smscode'), []);

  return (
    <>
      <Input
        labelText='Номер телефона'
        placeholder='Введите номер без +7'
        value={phone}
        onChange={handleChange}
        masked
      />
      <Button buttonText='Продолжить' onClick={handleClick} />
    </>
  );
};
