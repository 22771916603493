import LogoWallet from '../../../../assets/components/Header/LogoWallet.svg?react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import styles from './Wallet.module.scss';

export const Wallet = ({ crmUserData, userLoading }) => {
  const balance = crmUserData && crmUserData?.wallets?.code?.credit;
  const { width } = useWindowSize();

  if (width >= 768) {
    return (
      <>
        <div className={styles.desktopWrapper}>
          <div className={styles.badge}>
            <LogoWallet className={styles.walletIcon} />
          </div>
          <div className={styles.container}>
            <div className={styles.balance}>{balance ?? 0} ₽</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.wallet}>
      <div className={styles.icon}>₽</div>
      {userLoading ? <p>Loading...</p> : <div className={styles.balance}>{balance ?? 0}</div>}
    </div>
  );
};
