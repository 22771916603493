import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';

import BackSvg from '../../../assets/components/Staff/ArrowLeftSvg.svg?react';
import styles from './BackButton.module.css';

/**
 * Стрелка "Назад".
 *
 * @param {string} [props.navigateTo='/'] - Маршрут для навигации при клике. По умолчанию -1.
 * @param {string} [props.className] - Необязательные дополнительные CSS-классы.
 */
export const BackButton = ({ navigateTo = -1, className }) => {
  const navigate = useNavigate();
  return (
    <button className={clsx(styles.backButton, className)} onClick={() => navigate(navigateTo)}>
      <BackSvg />
    </button>
  );
};
