import { useContext } from 'react';

import { BonusesModalContext } from '../../../../../../context/BonusesModalContext';
import { prepareNewsImageString } from '../../../../../../utils/prepareNewsImageString';
import { Button } from '../../../../../atoms/Button/Button';
import Info from '../info.svg?react';
import styles from './BonusItem.module.css';
import Lock from './lock.svg?react';

export const BonusItem = ({ data, changeModalContent }) => {
  const { activateBonusMutation, setClickedBonusId } = useContext(BonusesModalContext);

  const img = prepareNewsImageString(data?.promo?.image, import.meta.env.VITE_STATIC_DOMAIN);

  const title = data?.promo?.ru?.title;

  const handleClick = bonusId => {
    setClickedBonusId(bonusId);
    if (data?.status === 'ALLOW') return activateBonusMutation.mutate({ bonusId });

    return changeModalContent('profile/bonuses/bonus', title);
  };

  const handleInfoClick = bonusId => {
    setClickedBonusId(bonusId);

    return changeModalContent('profile/bonuses/bonus', title);
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {data?.status === 'DISALLOW' && <div className={styles.lockBlur} />}
      <Info className={styles.infoIcon} onClick={() => handleInfoClick(data?.id)} />
      {data?.status === 'DISALLOW' && <Lock className={styles.lock} />}
      <p className={styles.title}>{title}</p>
      <Button
        className={styles.button}
        buttonText={data?.status === 'ALLOW' ? 'Активировать' : 'Подробнее'}
        onClick={() => handleClick(data?.id)}
      />
    </div>
  );
};
