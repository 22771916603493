import { clsx } from 'clsx';
import { memo } from 'react';
import { IMaskInput } from 'react-imask';

import styles from './Input.module.css';

const InputComponent = ({
  labelText,
  name,
  value = '',
  placeholder,
  disabled,
  onChange = () => {},
  maxLength,
  type = 'text',
  masked,
  mask = '+7 (000) 000-00-00',
  unmask = true,
}) => {
  return (
    <>
      {labelText && (
        <label className={clsx(styles.label, disabled && styles.disabled)} htmlFor={name}>
          {labelText}
        </label>
      )}
      {masked ? (
        <IMaskInput
          mask={mask}
          value={value}
          unmask={true}
          onAccept={value => onChange({ target: { value, name } })}
          disabled={disabled}
          className={clsx(styles.input, disabled && styles.disabled)}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      ) : (
        <input
          className={clsx(styles.input, disabled && styles.disabled)}
          id={name}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength}
        />
      )}
    </>
  );
};

/**
 * Input компонент для ввода данных с возможностью отображения метки (label).
 *
 * @param {string} [props.labelText] - Текст лэйбла.
 * @param {string} props.name - Имя инпута.
 * @param {string} [props.type='text'] - Тип инпута.
 * @param {string} [props.value=''] - Значение.
 * @param {string} [props.placeholder] - Плэйсхолдер.
 * @param {boolean} [props.disabled] - Флаг для отключения инпута.
 * @param {function} [props.onChange] - Функция-обработчик изменения значения.
 * @param {number} [props.maxLength] - Максимальная длина ввода.
 */
export const Input = memo(InputComponent);
