import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import styles from './PageHeader.module.css';

export const PageHeader = ({ goTo, title }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.pageHeader}>
      <div className={styles.backArrow} onClick={() => navigate(goTo)}>
        <ArrowLeftSvg />
      </div>
      <div>
        <h1 className={styles.pageTitle}>{title}</h1>
      </div>
    </div>
  );
};
