/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/effect-coverflow';

import * as Accordion from '@radix-ui/react-accordion';
import * as Progress from '@radix-ui/react-progress';
// import { Skeleton } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowLeftSvg from '../../../assets/components/Loyalty/ArrowLeftSvg.svg?react';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { Button } from '../../atoms/Button/Button';
import { LoyaltyBadge } from '../../atoms/LoyaltyBadge/LoyaltyBadge';
import { PrivilegeItem } from '../../atoms/privilegeItem/PrivilegeItem';
import styles from './Loyalty.module.scss';

const currentlevel = 4;
const levelWords = ['Зеленый', 'Белый', 'Бронза', 'Серебро', 'Золото', 'Платина'];
const privilegeList = [
  'Бонус на день рождение',
  'Персональный VIP менеджер',
  'Еженедельный кэшбэк 20%',
  'Mercedes E-Class',
  'Личный водитель',
];

const getStatusLevel = status => {
  const statusArray = ['green', 'white', 'bronze', 'silver', 'gold', 'platinum'];
  const statusIndex = statusArray.indexOf(status);
  return { level: statusIndex, word: levelWords[statusIndex] };
};

const getProgress = betData => {
  if (betData) {
    const currentBet = betData.sum_bet;
    const total = betData?.statuses[betData?.status].to;
    return Math.floor((currentBet * 100) / total);
  }
  return 0;
};

export const Loyalty = ({ changeContent }) => {
  const { getCrmRequest } = useCrmCall();
  const [initProgress, setInitProgress] = useState(0);
  const [slider, setSlider] = useState();
  const [activeIndex, setActiveIndex] = useState(currentlevel);
  const { setNotifications } = useContext(NotificationsContext);

  const { data, error } = useQuery({
    queryKey: ['loyalty'],
    queryFn: () => getCrmRequest('/user/loyalty'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });
  // console.log(betData);
  getProgress(data?.betData);

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }

    setTimeout(() => {
      setInitProgress(65);
    }, 1000);
  }, []);

  const handleClick = newContent => {
    changeContent(newContent);
  };
  console.log(data?.betData);

  return (
    <>
      <div className={styles.backWrapper}>
        {/* <Skeleton loading={!data?.betData}> */}
          <div className={styles.levelWrapper}>
            <LoyaltyBadge level={getStatusLevel(data?.betData?.status).level + 1} />
          </div>
        {/* </Skeleton> */}
        {getStatusLevel(data?.betData?.status).level + 1 >= 6 ? (
          <div className={styles.progressFinal}>
            <p>Вы достигли максимального уровня лояльности</p>
          </div>
        ) : (
          // <Skeleton loading={!data?.betData}>
            <div className={styles.progressBox}>
              <div className={styles.progressHeader}>
                <span>{getStatusLevel(data?.betData?.status).word}</span>
                <span>{levelWords[getStatusLevel(data?.betData?.status).level + 1]}</span>
              </div>
              <Progress.Root className={styles.progressRoot} value={65}>
                <Progress.Indicator
                  className={styles.progressIndicator}
                  style={{ transform: `translateX(-${100 - getProgress(data?.betData)}%)`, transition: '1s' }}
                />
              </Progress.Root>

              <div className={styles.progressFooter}>
                <span>{getProgress(data?.betData) + '%'}</span>
                <span>100%</span>
              </div>
            </div>
          // </Skeleton>
        )}
        {/* <Skeleton loading={!data?.betData}> */}
          <div className={styles.mainContent}>
            <div className={styles.sliderWrapper}>
              <div className={styles.arrowBtn} onClick={() => (slider.activeIndex = activeIndex - 1)}>
                <ArrowLeftSvg />
              </div>

              <Swiper
                effect='coverflow'
                spaceBetween={20}
                slidesPerView={3}
                centeredSlides={true}
                modules={[EffectCoverflow]}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 60,
                  modifier: 1,
                  scale: 0.9,
                  slideShadows: false,
                }}
                onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
                onSwiper={swiper => {
                  setSlider(swiper);
                  swiper.activeIndex = currentlevel - 1;
                  setActiveIndex(currentlevel - 1);
                }}
              >
                {levelWords.map(el => (
                  <SwiperSlide className={styles.slide}>{el}</SwiperSlide>
                ))}
              </Swiper>

              <div className={styles.arrowBtn}>
                <div className={clsx(styles.arrowBtn, styles.rotate)}>
                  <ArrowLeftSvg />
                </div>
              </div>
            </div>

            <div className={styles.content}>
              <div className={styles.contentHead}>
                <Button buttonText='Приз за уровень' className={styles.btn} />
                <Button buttonText='Еженедльный кэшбэк' className={styles.btn} />
              </div>

              {privilegeList.map((el, i) => (
                <PrivilegeItem text={el} disable={i > activeIndex - 1} />
              ))}
            </div>
          </div>
        {/* </Skeleton> */}

        <div className={styles.contentFooter}>
          <Accordion.Root className={styles.accordionRoot} type='single' defaultValue='item-1' collapsible>
            <Accordion.Item className={styles.accordionItem} value='item-1'>
              <Accordion.Trigger>Как начисляются очки ?</Accordion.Trigger>
              <Accordion.Content className={styles.accordionContent}>Какой то текст</Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>

          <Accordion.Root className={styles.accordionRoot} type='single' defaultValue='item-2' collapsible>
            <Accordion.Item className={styles.accordionItem} value='item-2'>
              <Accordion.Trigger>Как начисляются очки ?</Accordion.Trigger>
              <Accordion.Content className={styles.accordionContent}>Какой то текст</Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </div>
    </>
  );
};
