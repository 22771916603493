import { createContext, useState } from 'react';

const ModalContext = createContext();

const initialState = { requisiteNumber: '', amount: '', paymentMethod: '', bankName: '' };

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState(initialState);
  const [pageFrom, setPageFrom] = useState('');

  return (
    <ModalContext.Provider value={{ modalState, setModalState, pageFrom, setPageFrom }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
