import Icon from './icon.svg?react';
import styles from './TechnicalWorks.module.css';

export const TechnicalWorks = () => {
  return (
    <div className={styles.container}>
      <Icon />
      <p className={styles.text}>Ведутся технические работы.</p>
      <p className={styles.text}>Мы скоро вернемся!</p>
    </div>
  );
};
