import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { UserContext } from '../../../context/UserContext';
import { Button } from '../../atoms/Button/Button';
import { Input } from '../../molecules/Input/Input';
import { PasswordInput } from '../../molecules/PasswordInput/PasswordInput';
import styles from './Auth.module.css';

export const Login = ({ changeModalContent }) => {
  const [formData, setFormData] = useState({
    phone: '',
    password: '',
  });
  const { login } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();

  const navigate = useNavigate();

  const handleChange = useCallback(e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const authData = await postCrmRequest('/auth/login', {
        ...formData,
        phone: formData.phone.startsWith('7') ? formData.phone : `7${formData.phone}`,
      });
      if (authData.status === 200) {
        localStorage.setItem('authToken', authData?.token);
        login({
          isAuthenticated: Boolean(authData?.token),
          user: authData?.user,
        });
        navigate('/');
      }
    } catch (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  };

  return (
    <>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <Input
          labelText='Номер телефона'
          placeholder='Ввести номер'
          name='phone'
          value={formData.phone}
          onChange={handleChange}
          masked={true}
        />
        <PasswordInput
          labelText='Пароль'
          placeholder='Ввести пароль'
          name='password'
          value={formData.password}
          onChange={handleChange}
        />
        <Button className={styles.button} buttonText='Войти' />
        <p className={styles.recoveryLink} onClick={() => changeModalContent('login/recovery', 'Восстановить пароль')}>
          Забыли пароль?
        </p>
      </form>
    </>
  );
};
