import Modal from 'react-modal';

import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { BackButton } from '../../../atoms/BackButton/BackButton';
import { CloseButton } from '../../../atoms/CloseButton/CloseButton';
import styles from './ModalComponent.module.scss';

export const ModalComponent = ({
  isOpen,
  onRequestClose,
  modalContent,
  modalName,
  handleGoBack,
  Content,
  closeModal,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalHeader}>
        {modalContent !== 'profile' && (
          <div className={styles.changeToProfileButton} onClick={handleGoBack}>
            <BackButton />
            {capitalizeFirstLetter(modalName?.current || modalContent)}
          </div>
        )}
        <CloseButton onClick={closeModal} />
      </div>
      {Content && <Content />}
    </Modal>
  );
};
