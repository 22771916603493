import ErrorIcon from '../icons/error.svg?react';
import ProgressIcon from '../icons/progress.svg?react';
import SuccessIcon from '../icons/success.svg?react';

const icons = {
  NEW: ProgressIcon, // Заявка создана, ждет подтверждения сотрудником
  APPROVED: ProgressIcon, // Заявка подтверждена сотрудником, ждет создания заявки в платежке
  NOT_APPROVED: ErrorIcon, // Сотрудник отказал в заявке на выплату
  ACTIVE: ProgressIcon, // Заявка создана в ПС, ждет когда сотрудник начнет выплату
  IN_PROGRESS: ProgressIcon, // Сотрудник ПС взял в работу тикет
  COMPLETED: SuccessIcon, // Заявка выполнена
  DECLINED: ErrorIcon, // Заявка отменена сотрудником ПС
};

export const getIconByStatus = status => icons[status] || icons.NEW;
