import { useNavigate } from 'react-router-dom';

import BTC from '../../../assets/components/Footer/BTC.svg?react';
import InstagramSvg from '../../../assets/components/Footer/InstagramSvg.svg?react';
import P2P from '../../../assets/components/Footer/P2P.svg?react';
import T from '../../../assets/components/Footer/T.svg?react';
import TGChat from '../../../assets/components/Footer/TGChat.svg?react';
import TgSvg from '../../../assets/components/Footer/TgSvg.svg?react';
import VK from '../../../assets/components/Footer/VK.svg?react';
import XSvg from '../../../assets/components/Footer/XSvg.svg?react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Footer.module.scss';

export const Footer = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  if (isDesktop) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.footer}>
          <div className={styles.leftContainer}>
            <div className={styles.logo}>
              <LogoComponent variant='big' />
            </div>
            <div className={styles.description}>
              <p>
                "Восход" — это современное онлайн-казино, предлагающее широкий выбор популярных слотов, захватывающие
                настольные игры и живое казино с реальными дилерами.
              </p>
            </div>
            <div className={styles.links}>
              <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
                <TgSvg />
              </a>
              <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
                <VK />
              </a>
              <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
                <TGChat />
              </a>
              <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
                <InstagramSvg />
              </a>
              <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
                <XSvg />
              </a>
            </div>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.navigation}>
              <h6>Казино</h6>
              <p onClick={() => navigate('/bonusesPage')}>Бонусы</p>
              <p onClick={() => navigate('/loyaltyPage')}>Лояльность</p>
              <p onClick={() => navigate('/providersPage')}>Провайдеры</p>
            </div>
            <div className={styles.navigation}>
              <h6>Информация</h6>
              <p onClick={() => navigate('/')}>Новости</p>
              <p onClick={() => navigate('/FAQPage')}>Вопросы и ответы</p>
              <p onClick={() => navigate('/rulesPage')}>Правила</p>
              <p onClick={() => navigate('/')}>Контакты</p>
              <p onClick={() => navigate('/')}>Аффилиатам</p>
            </div>
          </div>
        </div>
        <div>
          <hr className={styles.devider} />
        </div>
        <div className={styles.techs}>
          <P2P />
          <T />
          <BTC />
          <img src='./Tech4.png' alt='NoData' className={styles.techImg} />
          <img src='./Tech5.png' alt='NoData' className={styles.techImg} />
        </div>
        <div className={styles.copyright}>
          Copyright © <span>******</span> All Reserved
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.footer}>
        <div className={styles.logo}>
          <LogoComponent variant='big' />
        </div>
        <div className={styles.links}>
          <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
            <TgSvg />
          </a>
          <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
            <VK />
          </a>
          <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
            <TGChat />
          </a>
          <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
            <InstagramSvg />
          </a>
          <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
            <XSvg />
          </a>
        </div>
        <div className={styles.refs}>
          <div onClick={() => navigate('/bonusesPage')}>Бонусы</div>
          <div onClick={() => navigate('/loyaltyPage')}>Лояльность</div>
          <div onClick={() => navigate('/providersPage')}>Провайдеры</div>
          <div onClick={() => navigate('/rulesPage')}>Правила</div>
          <div onClick={() => navigate('/FAQPage')}>FAQ</div>
          <div onClick={() => navigate('/')}>Контакты</div>
        </div>
        <div className={styles.techs}>
          <P2P />
          <T />
          <BTC />
          <img src='./Tech4.png' alt='NoData' className={styles.techImg} />
          <img src='./Tech5.png' alt='NoData' className={styles.techImg} />
        </div>
        <div className={styles.copyright}>
          Copyright © <span>******</span> All Reserved
        </div>
      </div>
    </div>
  );
};
