import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from '../components/pages/404/NotFoundPage';
import { TechnicalWorks } from '../components/pages/500/TechnicalWorks';
import { Callback } from '../components/pages/Auth/ui/Callback/Callback';
import { BonusesPage } from '../components/pages/BonusesPage/BonusesPage';
import { FAQ } from '../components/pages/FAQ/FAQ';
import { GamePage } from '../components/pages/GamePage/GamePage';
import { Loyalty } from '../components/pages/Loyalty/Loyalty';
import { MainPage } from '../components/pages/MainPage/MainPage';
import { NewsDetailPage } from '../components/pages/NewsPage/NewsDetailPage';
import { Providers } from '../components/pages/Providers/Providers';
import { Rules } from '../components/pages/Rules/Rules';
import { Sign } from '../components/pages/Sign/Sign';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}>
        <Route path='/providers/games' element={<MainPage />} />
        <Route path='/news' element={<MainPage />} />
        <Route path='/profile' element={<MainPage />} />
        <Route path='/loyalty' element={<MainPage />} />
        <Route path='/profile/bonuses' element={<MainPage />} />
        <Route path='/profile/bonuses/bonus' element={<MainPage />} />
        <Route path='/profile/loyalty' element={<MainPage />} />
        <Route path='/profile/providers' element={<MainPage />} />
        <Route path='/profile/transactions' element={<MainPage />} />
        <Route path='/profile/settings' element={<MainPage />} />
        <Route path='/deposit' element={<MainPage />} />
        <Route path='/withdrawal' element={<MainPage />} />
        <Route path='withdrawal/requisites' element={<MainPage />} />
        <Route path='/withdrawal/requisites/add' element={<MainPage />} />
        <Route path='/smsCode' element={<MainPage />} />
        <Route path='/bank' element={<MainPage />} />
        <Route path='/verifyEmail' element={<MainPage />} />
        <Route path='/verifyPhone' element={<MainPage />} />
        <Route path='/favorite' element={<MainPage />} />
        <Route path='/category' element={<MainPage />} />
        <Route path='/providers' element={<MainPage />} />
        <Route path='/novelties' element={<MainPage />} />
        <Route path='/category/games' element={<MainPage />} />
      </Route>
      <Route path='/register' element={<Sign />} />
      <Route path='/login' element={<Sign />} />
      <Route path='/bonusesPage' element={<BonusesPage />} />
      <Route path='/providersPage' element={<Providers />} />
      <Route path='/rulesPage' element={<Rules />} />
      <Route path='/FAQPage' element={<FAQ />} />
      <Route path='/game/:id' element={<GamePage />} />
      <Route path='/news/:id' element={<NewsDetailPage />} />
      <Route path='/technical-works' element={<TechnicalWorks />} />
      <Route path='/callback/*' element={<Callback />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
