import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';

import DepSvg from '../../../../assets/components/NavBar/Deposit.svg?react';
import HomeSvg from '../../../../assets/components/NavBar/Home.svg?react';
import SupSvg from '../../../../assets/components/NavBar/Support.svg?react';
import styles from './NavBarBottom.module.scss';

export const NavBarBottom = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.navBarBottom}>
        <div className={styles.buttonsContainer}>
          <button className={clsx(styles.buttonRight, 'show-verbox')}>
            <div className={styles.buttonRightIco}>
              <SupSvg />
            </div>
            Поддержка
          </button>
          <button onClick={() => navigate('/')} className={styles.buttonCenter}>
            <div className={styles.buttonCenterIco}>
              <HomeSvg />
            </div>
            Главная
          </button>
          <button onClick={() => navigate('/deposit')} className={styles.buttonLeft}>
            <div className={styles.buttonLeftIco}>
              <DepSvg />
            </div>
            Депозит
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBarBottom;
