import { useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { authUrl } from '../../../constants';
import { UserContext } from '../../../context/UserContext';
import { PageHeader } from '../../molecules/PageHeader/PageHeader';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './BonusesPage.module.css';
import { BonusPageItem } from './ui/BonusPageItem/BonusPageItem';

export const BonusesPage = () => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (!isAuthenticated) {
      window.location = authUrl;
    } else {
      navigate('/profile/bonuses');
    }
  });
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{import.meta.env.VITE_DOMAIN_NAME} - Все бонусы</title>
        <link rel='canonical' href={`http://${import.meta.env.VITE_DOMAIN_NAME}.home/bonusesPage`} />
      </Helmet>
      <Header />
      <PageHeader goTo='/' title='Все бонусы' />
      <div className={styles.container}>
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
      </div>
      <NavBarBottom />
    </>
  );
};
