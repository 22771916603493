const modalTranslations = {
  profile: 'Профиль',
  settings: 'Настройки',
  'profile/transactions': 'История транзакций',
  deposit: 'Депозит',
  withdrawal: 'Вывод',
  smscode: 'Введите код из СМС',
  bank: 'Банк',
  bonuses: 'Бонусы',
  loyalty: 'Лояльность',
  providers: 'Провайдеры',
  verifyemail: 'Подтвердить email',
  verifyphone: 'Подтвердить телефон',
  'profile/bonuses': 'Мои Бонусы',
};

export const translateModalName = modalName => {
  const translatedName = modalTranslations[modalName];

  return translatedName || modalName;
};
