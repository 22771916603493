import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';

import { useCrmCall } from '../api/useCrmCall';
import { NotificationsContext } from './NotificationsContext';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';

const BonusesModalContext = createContext();

const BonusesModalProvider = ({ children }) => {
  const [filteredBonuses, setFilteredBonuses] = useState({});
  const [enabled, setEnabled] = useState(false);
  const [clickedBonusId, setClickedBonusId] = useState();
  const [clickedBonus, setClickedBonus] = useState();
  const {
    userState: { user },
  } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const activateBonusMutation = useMutation({
    mutationFn: data => postCrmRequest('/user/activate_bonus', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bonuses'] });
      navigate('/');
      setNotifications({ type: 'success', message: 'SUCCESS_BONUS_ACTIVATE' });
    },
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const deactivateBonusMutation = useMutation({
    mutationFn: data => postCrmRequest('/user/deactivate_bonus', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bonuses'] });
      setNotifications({ type: 'success', message: 'SUCCESS_BONUS_DEACTIVATE' });
      navigate('/');
    },
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const userId = user?.userId || null;

  const { data: bonuses, error } = useQuery({
    queryKey: ['bonuses', userId],
    queryFn: () => postCrmRequest('/user/bonuses', { userId }),
    enabled,
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  useEffect(() => {
    setFilteredBonuses({
      allowedBonuses: bonuses?.filter(bonus => bonus.status === 'ALLOW'),
      notAllowedBonuses: bonuses?.filter(bonus => bonus.status !== 'ALLOW'),
      inProgressBonus: bonuses?.find(bonus => bonus.status === 'IN_PROGRESS'),
    });
  }, [bonuses]);

  useEffect(() => {
    setClickedBonus(bonuses?.find(bonus => bonus?.id === clickedBonusId));
  }, [clickedBonusId]);

  return (
    <BonusesModalContext.Provider
      value={{
        filteredBonuses,
        setEnabled,
        clickedBonusId,
        setClickedBonusId,
        activateBonusMutation,
        clickedBonus,
        deactivateBonusMutation,
      }}
    >
      {children}
    </BonusesModalContext.Provider>
  );
};

export { BonusesModalContext, BonusesModalProvider };
