import { useContext } from 'react';

import { ModalContext } from '../context/ModalContext';

export const useGetModalSubmitData = pageFrom => {
  const { modalState } = useContext(ModalContext);

  const submitDataMap = {
    withdrawal: {
      reqType: modalState?.paymentMethod,
      requisite: {
        reqNum: modalState?.requisiteNumber,
        comment: modalState?.bankName,
      },
      code: '123456',
    },
  };

  return submitDataMap[pageFrom];
};
