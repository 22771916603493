import ReverseButton from '../../../assets/components/CurrencyTransfer/ReverseButton.svg?react';
import { TransferInput } from '../../molecules/TransferInput/TransferInput';
import styles from './CurrencyTransfer.module.css';

export const CurrencyTransfer = ({ from, to, setFromBalanceName, setToBalanceName }) => {
  const handleReverse = () => {
    setFromBalanceName(to);
    setToBalanceName(from);
  };

  return (
    <div className={styles.transferContainer}>
      <TransferInput innerLabel='Из' balanceName={from} />
      <TransferInput innerLabel='На' currency='C' balanceName={to} />
      <div className={styles.reverseButton} onClick={handleReverse}>
        <ReverseButton />
      </div>
    </div>
  );
};
