import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import { ModalContext } from '../../../../context/ModalContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { useGetModalSubmitData } from '../../../../hooks/useGetModalSubmitData';
import { Button } from '../../../atoms/Button/Button';
import styles from './SmsCodeModal.module.css';

export const SmsCodeModal = ({ changeModalContent }) => {
  const { mutationRequest, pageFrom } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const [code, setCode] = useState(new Array(4).fill(''));
  const [timeLeft, setTimeLeft] = useState(60);
  const inputsRef = useRef([]);
  const { state } = useLocation();
  const { postCrmRequest } = useCrmCall();
  const smsMutation = useMutation({
    mutationFn: state => postCrmRequest('/payment/send_verification_sms', state),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const queryClient = useQueryClient();
  const mutationData = useGetModalSubmitData(pageFrom);

  const mutationsMap = {
    withdrawal: useMutation({
      mutationFn: data => postCrmRequest('/payment/payout_add_requisite', data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['userData'] });
        changeModalContent('withdrawal-step2', 'Банковские карты', true);
      },
      onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
    }),
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      // Проверяем, что введённое значение - цифра
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Если это не последнее поле, переводим фокус на следующее
      if (index < code.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    } else if (value === '') {
      // Если пользователь удаляет символ
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);

      // Переводим фокус на предыдущее поле
      if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handleResendCode = () => {
    smsMutation.mutate(state);
    if (smsMutation.isSuccess) setTimeLeft(60);
  };

  return (
    <div className={styles.smsContainer}>
      <p className={styles.modalTitle}>Введите код из СМС для подтверждения личности</p>
      <div className={styles.inputsContainer}>
        {code.map((digit, idx) => (
          <input
            key={idx}
            ref={el => (inputsRef.current[idx] = el)}
            className={clsx(styles.codeInput, mutationRequest?.isError && styles.errorInput)}
            type='text'
            maxLength='1'
            value={digit}
            onChange={e => handleChange(e, idx)}
          />
        ))}
      </div>
      {timeLeft !== 0 && (
        <p className={styles.retryTimer}>
          Повторить код через: <span>{timeLeft}c</span>
        </p>
      )}
      {mutationRequest?.isError ? <p className={styles.errorMessage}>Неверный код из СМС</p> : null}
      {timeLeft === 0 && (
        <p className={styles.getNewCode} onClick={handleResendCode}>
          Отправить новый код
        </p>
      )}
      <Button
        buttonText={'Продолжить'}
        isLoading={smsMutation.isPending || mutationRequest?.isPending}
        onClick={() => mutationsMap[pageFrom]?.mutate(mutationData)}
      />
    </div>
  );
};
