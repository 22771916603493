import Avatar from '../../../../assets/components/Header/Avatar.svg?react';
import Stripes from '../../../../assets/components/Header/Stripes.svg?react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import styles from './ProfileIcon.module.scss';

export const ProfileIcon = ({ user, text, userLoading, onClick, openProfileModal, icon = false }) => {
  const { width } = useWindowSize();

  if (width >= 768) {
    return (
      <>
        <button className={styles.desktopWrapper} onClick={onClick || (() => openProfileModal('profile'))}>
          <Stripes className={styles.icon} />
          <p>{text}</p>
          {icon && <img src='/images/components/Header/DeskAvatar.png' alt='Аватар' />}
        </button>
      </>
    );
  }

  return (
    <button className={styles.wrapper} onClick={() => openProfileModal('profile')}>
      {userLoading ? <p>Loading...</p> : <Avatar />}
    </button>
  );
};
