import { useCallback, useContext, useState } from 'react';

import { UserContext } from '../../../../context/UserContext';
import { ModalContext } from '../../../../context/ModalContext';
import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../molecules/Input/Input';

export const VerifyPhone = ({ changeModalContent }) => {
  const {
    userState: { user },
  } = useContext(UserContext);
  const { setPageFrom } = useContext(ModalContext);
  const [inputState, setInputState] = useState('');

  const handleChange = useCallback(e => {
    const regExp = /^[0-9]*$/; // Разрешаем только цифры

    if (regExp.test(e.target.value)) {
      setInputState(e.target.value);
    }
  }, []);

  const handleSubmit = data => {
    setPageFrom('verifyphone');
    changeModalContent('smscode');
  };

  const data = {
    id: user?.userId,
    email: inputState,
  };

  return (
    <>
      <Input
        name='verifyPhone'
        labelText='Повторите номер телефона'
        maxLength={11}
        value={inputState}
        onChange={handleChange}
      />
      <Button buttonText='Продолжить' onClick={() => handleSubmit(data)} />
    </>
  );
};
