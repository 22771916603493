import { createContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

const getAppliedTheme = theme => {
  const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return theme === 'system' ? (systemPrefersDark ? 'dark' : 'light') : theme;
};

const useSystemThemeListener = theme => {
  useEffect(() => {
    const systemThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleSystemThemeChange = () => {
      document.documentElement.dataset.theme = getAppliedTheme(theme);
    };

    handleSystemThemeChange(); // Инициализация при монтировании
    systemThemeQuery.addEventListener('change', handleSystemThemeChange);

    return () => {
      systemThemeQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, [theme]);
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'system');

  // Применение темы и сохранение в localStorage
  useEffect(() => {
    document.documentElement.dataset.theme = getAppliedTheme(theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Отслеживание изменений системной темы
  useSystemThemeListener(theme);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeProvider };
