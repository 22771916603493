import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowSvg from '../../../../../assets/components/Profile/ArrowSvg.svg?react';
import styles from './WalletDropdown.module.css';

export const WalletDropdown = ({ user }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };
  const { t } = useTranslation('WalletDropdown');

  const realAndBonus = (user?.wallets?.code?.credit ?? 0) + (user?.wallets?.code?.air ?? 0);

  return (
    <div className={styles.walletDropdownContainer} onClick={toggleDropdown}>
      <div className={styles.walletDropdown}>
        <div className={styles.icon}>
          <p>₽</p>
        </div>
        <div className={styles.balance}>{realAndBonus}</div>
        <div className={styles.walletArrow}>
          <ArrowSvg />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.walletDropdownOptions}>
          <div className={styles.walletDropdown}>
            <div className={styles.icon}>
              <p>Р</p>
            </div>
            <div className={styles.balance}>{user?.wallets?.code?.credit ?? 0}</div>
            <div style={{ position: 'absolute', right: '12px' }}>{t('real')}</div>
          </div>
          <div className={styles.walletDropdown}>
            <div className={styles.icon}>
              <p>Б</p>
            </div>
            <div className={styles.balance}>{user?.wallets?.code?.air ?? 0}</div>
            <div style={{ position: 'absolute', right: '12px' }}>{t('bonus')}</div>
          </div>
          <div className={styles.walletDropdown}>
            <div className={styles.icon}>
              <p>С</p>
            </div>
            <div className={styles.balance}>{user?.wallets?.deposit?.credit ?? 0}</div>
            <div style={{ position: 'absolute', right: '12px' }}>{t('safe')}</div>
          </div>
        </div>
      )}
    </div>
  );
};
