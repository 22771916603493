import { memo } from 'react';

import styles from './CloseButton.module.css';
import XSvg from './x.svg?react';

/**
 * Компонент кнопки закрытия с иконкой 'X'.
 *
 * @param {function} props.onClick - Функция, вызываемая при клике на кнопку.
 */
export const CloseButton = memo(({ onClick = () => {} }) => {
  return (
    <button className={styles.closeIcon} onClick={onClick}>
      <XSvg />
    </button>
  );
});
