import { clsx } from 'clsx';
import { memo, useCallback, useState } from 'react';

import styles from './PasswordInput.module.css';

const PasswordInputComponent = ({ name, labelText, value, placeholder, disabled, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  }, []);

  return (
    <>
      {labelText && (
        <label className={clsx(styles.label, disabled && styles.disabled)} htmlFor={name}>
          {labelText}
        </label>
      )}
      <div className={clsx(styles.passwordContainer, disabled && styles.disabled)}>
        <input
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          className={clsx(styles.passwordInput, disabled && styles.disabled)}
        />
        <button
          className={clsx(styles.togglePasswordButton, disabled && styles.disabled)}
          type='button'
          onClick={togglePasswordVisibility}
        >
          {showPassword ? '🙈' : '👁️'}
        </button>
      </div>
    </>
  );
};

/**
 * PasswordInput компонент для ввода пароля с возможностью переключения видимости пароля.
 *
 * @param {string} props.name - Имя инпута (атрибут name).
 * @param {string} props.labelText - Текст метки (label), отображаемой рядом с инпутом.
 * @param {string} props.value - Значение инпута.
 * @param {function} props.onChange - Функция-обработчик изменения значения инпута.
 */
export const PasswordInput = memo(PasswordInputComponent);
