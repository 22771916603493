import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';

import { useCrmCall } from '../../../../api/useCrmCall';
import { ModalContext } from '../../../../context/ModalContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { Button } from '../../../atoms/Button/Button';
import { CardSelect } from '../../../molecules/CardSelect/CardSelect';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { useNavigate } from 'react-router-dom';

export const WithdrawalRequisites = ({ changeModalContent }) => {
  const [activeCard, setActiveCard] = useState(null);
  const { modalState, setModalState } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const initWithdrawalMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_init', data),
    onSuccess: () => {
      queryClient.invalidateQueries(['userBalance']);
      setActiveCard(null);
      setModalState({ requisiteNumber: '', amount: '', paymentMethod: '', bankName: '' });
      setNotifications({ type: 'success', message: 'SUCCESS_WITHDRAWAL' });
      navigate('/');
    },
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const data = {
    cardId: activeCard?.id,
    amount: modalState?.amount,
  };

  useEffect(() => {
    const modalContent = document.querySelector('.ReactModal__Content');
    if (modalContent) modalContent.style.overflowY = 'visible';
    return () => {
      const modalContent = document.querySelector('.ReactModal__Content');
      if (modalContent) modalContent.style.overflowY = 'scroll';
    };
  }, []);

  return (
    <>
      <CardSelect activeCard={activeCard} setActiveCard={setActiveCard} changeModalContent={changeModalContent} />
      <NumberInput labelText='Сумма вывода' name='amount' disabled={true} value={modalState?.amount || ''} />
      <Button
        buttonText={'Вывод средств'}
        isLoading={initWithdrawalMutation?.isPending}
        onClick={() => initWithdrawalMutation?.mutate(data)}
      />
    </>
  );
};
