import { useEffect } from 'react';

/**
 * Хук для отключения взаимодействия с элементом при прокрутке контейнера с атрибутом data-block-interactions.
 * Должен вызываться в том компоненте/модалке, в которой подразумевается прокрутка содержимого.
 */
export const useDisableInteractionOnScroll = () => {
  useEffect(() => {
    // Ищем элемент с атрибутом data-block-interactions в DOM
    const scrollableParent = document.querySelector('[data-block-interactions]');
    if (!scrollableParent) return;

    const handleScroll = () => {
      scrollableParent.style.pointerEvents = 'none';

      setTimeout(() => {
        scrollableParent.style.pointerEvents = '';
      }, 500);
    };

    scrollableParent.addEventListener('scroll', handleScroll);

    // Очищаем обработчик при размонтировании
    return () => {
      scrollableParent.removeEventListener('scroll', handleScroll);
    };
  }, []);
};
