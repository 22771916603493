import { clsx } from 'clsx';
import { memo, useState } from 'react';

import ArrowSvg from '../../../assets/components/Profile/ArrowSvg.svg?react';
import { Input } from '../Input/Input';
import styles from './ExpandableInput.module.css';

const ExpandableInputComponent = ({ name, title, value, disabled, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleInput = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.expandableContainer}>
      <div className={clsx(styles.header, disabled && styles.disabled)} onClick={toggleInput}>
        <p>{title}</p>
        <div className={`${styles.arrow} ${isExpanded ? `${styles.expanded}` : ''}`}>
          <ArrowSvg />
        </div>
      </div>
      <div className={`${styles.inputContainer} ${isExpanded ? `${styles.expanded}` : ''}`}>
        <Input
          className={styles.expandableInput}
          name={name}
          value={value}
          type='text'
          placeholder='Введите промо-код'
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

/**
 * Отображает заголовок с возможностью раскрытия/скрытия текстового поля. При нажатии на заголовок раскрывается input для ввода данных.
 *
 * @param {string} props.title Заголовок, отображаемый над input.
 * @param {boolean} [props.disabled=false] Отключает input, если true.
 *
 */

export const ExpandableInput = memo(ExpandableInputComponent);
