import axios from 'axios';
import { useEffect, useState } from 'react';

import ArrowLeftSvg from '../../../assets/components/Staff/ArrowLeftSvg.svg?react';
import styles from './Rules.module.css';

export const Rules = ({ changeContent }) => {
  const handleClick = newContent => {
    changeContent(newContent);
  };

  // const [RulesText, setRulesText] = useState('');

  // useEffect(() => {
  //     const fetchRulesText = async () => {
  //         try {
  //             const response = await axios.get('', {
  //                 headers: {

  //                 },
  //             });
  //             setRulesText(response.);
  //         } catch (error) {
  //             console.error('Ошибка при получении текста Rules:', error);
  //         }
  //     };

  //     fetchRulesText();
  // }, []);
  return (
    <>
      <div className={styles.backWrapper} onClick={() => handleClick('Home')}>
        <ArrowLeftSvg />
        Rules
      </div>
      <div className={styles.content} />
    </>
  );
};
