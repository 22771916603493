import styles from './Recommended.module.scss';
import { GameList } from './ui/GameList';

export const Recommended = () => {
  return (
    <div className={styles.container}>
      <GameList />
    </div>
  );
};
