import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { NotificationsContext } from '../../../../../../context/NotificationsContext';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import { CategoryItem } from '../CategoryItem/CategoryItem';

const categories = [
  { title: 'Доступные с бонусом', category: 'with-bonus' },
  { title: 'Популярные', category: 'popular' },
  { title: 'Купить бонус', category: 'bonus-buy' },
  { title: 'Megaways', category: 'megaways' },
  { title: 'Live Casino', category: 'live-casino' },
  { title: 'Избранное', category: 'favorites' },
  { title: 'promo', category: 'promo' },
  { title: 'Топ 4', category: 'top4' },
  { title: 'Новинки', category: 'new' },
  { title: 'Без бонусов', category: 'bonus-restriction' },
];

export const ModalContentCategory = () => {
  const { setFilteredGames, changeModalContent } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();

  useDisableInteractionOnScroll();

  const getGamesByCategory = useMutation({
    mutationFn: key => postCrmRequest(`/games/games_by_key`, { lang: 'ru', key }),
    onSuccess: data => setFilteredGames(data),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const handleClick = key => {
    setFilteredGames([]);
    getGamesByCategory.mutate(key);
    changeModalContent('category/games', key);
  };

  return (
    <>
      {categories?.map(({ title, category }) => {
        return <CategoryItem key={title} title={title} onClick={() => handleClick(category)} />;
      })}
    </>
  );
};
