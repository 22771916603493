import { prepareNewsImageString } from '../../../../../../utils/prepareNewsImageString';
import Info from '../info.svg?react';
import styles from './ActiveBonusItem.module.css';
import { useContext } from 'react';
import { BonusesModalContext } from '../../../../../../context/BonusesModalContext';

export const ActiveBonusItem = ({ data, changeModalContent }) => {
  const { setClickedBonusId } = useContext(BonusesModalContext);

  const img = prepareNewsImageString(data?.promo?.image, import.meta.env.VITE_STATIC_DOMAIN);

  const handleInfoClick = bonusId => {
    setClickedBonusId(bonusId);

    return changeModalContent('profile/bonuses/bonus', data?.promo?.ru?.title);
  };
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Info className={styles.infoIcon} onClick={() => handleInfoClick(data?.id)} />
      <p className={styles.title}>{data?.promo?.ru?.title}</p>
    </div>
  );
};
